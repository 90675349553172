
import * as THREE from "../../libs/three.js/build/three.module.js";

export class NavigationCube extends THREE.Object3D {

	constructor(viewer){
		super();

		this.viewer = viewer;

		this.normalFaces = {
			"U": NavigationCube.createPlaneMaterial('U.png'),
			"D": NavigationCube.createPlaneMaterial('D.png'),
			"L": NavigationCube.createPlaneMaterial('L.png'),
			"R": NavigationCube.createPlaneMaterial('R.png'),
			"F": NavigationCube.createPlaneMaterial('F.png'),
			"B": NavigationCube.createPlaneMaterial('B.png'),
		}

		this.highlightedFaces = {
			"U": NavigationCube.createPlaneMaterial('U_h.png'),
			"D": NavigationCube.createPlaneMaterial('D_h.png'),
			"L": NavigationCube.createPlaneMaterial('L_h.png'),
			"R": NavigationCube.createPlaneMaterial('R_h.png'),
			"F": NavigationCube.createPlaneMaterial('F_h.png'),
			"B": NavigationCube.createPlaneMaterial('B_h.png'),
		}

		let planeGeometry = new THREE.PlaneGeometry(1, 1);

		this.front = new THREE.Mesh(planeGeometry, this.normalFaces["F"]);
		this.front.position.y = -0.5;
		this.front.rotation.x = Math.PI / 2.0;
		this.front.updateMatrixWorld();
		this.front.name = "F";
		this.add(this.front);

		this.back = new THREE.Mesh(planeGeometry, this.normalFaces["B"]);
		this.back.position.y = 0.5;
		this.back.rotation.x = Math.PI / 2.0;
		this.back.updateMatrixWorld();
		this.back.name = "B";
		this.add(this.back);

		this.left = new THREE.Mesh(planeGeometry, this.normalFaces["L"]);
		this.left.position.x = -0.5;
		this.left.rotation.y = Math.PI / 2.0;
		this.left.updateMatrixWorld();
		this.left.name = "L";
		this.add(this.left);

		this.right = new THREE.Mesh(planeGeometry, this.normalFaces["R"]);
		this.right.position.x = 0.5;
		this.right.rotation.y = Math.PI / 2.0;
		this.right.updateMatrixWorld();
		this.right.name = "R";
		this.add(this.right);

		this.bottom = new THREE.Mesh(planeGeometry, this.normalFaces["D"]);
		this.bottom.position.z = -0.5;
		this.bottom.updateMatrixWorld();
		this.bottom.name = "D";
		this.add(this.bottom);

		this.top = new THREE.Mesh(planeGeometry, this.normalFaces["U"]);
		this.top.position.z = 0.5;
		this.top.updateMatrixWorld();
		this.top.name = "U";
		this.add(this.top);

		this.width = 150; // in px
		this.yOffset = 70; // in px

		this.highlightedFace = "";

		this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, -1, 1);
		this.camera.position.copy(new THREE.Vector3(0, 0, 0));
		this.camera.lookAt(new THREE.Vector3(0, 1, 0));
		this.camera.updateMatrixWorld();
		this.camera.rotation.order = "ZXY";

		let onMouseDown = (event) => {
			if (!this.visible)
				return;
			let pickedFace = this.raycast(new THREE.Vector2(event.clientX, event.clientY), event.target);
			
			if (pickedFace)
				this.viewer.setView(pickedFace.name);
		};

		let onMouseMove = (event) => {
			if (!this.visible)
				return;

			let pickedFace = this.raycast(new THREE.Vector2(event.clientX, event.clientY), event.target);

			if (pickedFace) {
				if (pickedFace.name === this.highlightedFace)
					return;
				this.highlightFace(pickedFace.name);
			}
			else
				this.unhighlightFace(this.highlightedFace);
		};

		this.viewer.renderer.domElement.addEventListener('mousedown', onMouseDown, false);
		this.viewer.renderer.domElement.addEventListener('mousemove', onMouseMove, false);
	}

	static createPlaneMaterial(img) {
		let material = new THREE.MeshBasicMaterial({
			depthTest: true,
			depthWrite: true,
			side: THREE.DoubleSide
		});
		new THREE.TextureLoader().load(
			exports.resourcePath + '/textures/navigation/' + img,
			function (texture) {
				texture.anisotropy = viewer.renderer.capabilities.getMaxAnisotropy();
				material.map = texture;
				material.needsUpdate = true;
			});
		return material;
	}

	highlightFace(face) {
		if (face === "" || this.highlightedFace === face)
			return;

		this.unhighlightFace(this.highlightedFace)

		let faceMesh = this.getFace(face);
		faceMesh.material = this.highlightedFaces[faceMesh.name];

		this.highlightedFace = face;
	}

	unhighlightFace(face) {
		if (this.highlightedFace === "" || face === "" || this.highlightedFace !== face)
			return;

		let faceMesh = this.getFace(face);
		faceMesh.material = this.normalFaces[faceMesh.name];

		this.highlightedFace = "";
    }

	getFace(faceName) {
		return this.children.find(f => f.name === faceName);
    }

	update(rotation) {
		this.camera.rotation.copy(rotation);
		this.camera.updateMatrixWorld();
	}

	raycast(clickPoint, clientCanvas) {
		let viewportOffset = clientCanvas.getBoundingClientRect();
		let mouse = new THREE.Vector2();
		mouse.x = (clickPoint.x - viewportOffset.x) - (clientCanvas.clientWidth - this.width);
		mouse.y = (clickPoint.y - viewportOffset.y) - this.yOffset;

		if (mouse.x < 0 || mouse.y > this.width) return null;

		mouse.x = (mouse.x / this.width) * 2 - 1;
		mouse.y = -(mouse.y / this.width) * 2 + 1;

		let raycaster = new THREE.Raycaster();
		raycaster.setFromCamera(mouse, this.camera);
		raycaster.ray.origin.sub(this.camera.getWorldDirection(new THREE.Vector3()));

		let intersects = raycaster.intersectObjects(this.children);

		var pickedFace = null;
		let minDistance = 1000;
		for (let i = 0; i < intersects.length; i++) {
			if (intersects[i].distance < minDistance) {
				pickedFace = intersects[i].object;
				minDistance = intersects[i].distance;
			}
		}

		return pickedFace;
    }
}
